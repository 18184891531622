/**
 * 生产管理
 */
 import req from './index.js'

// 班次选择
export const productionInforTeamSelect = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforTeamSelect',param) };
export const productionInforEquipmentOutputByDayQuery = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentOutputByDayQuery',param) };//产量查询
export const productionInforEquipmentOutputByDayModification = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentOutputByDayModification',param) };//产量录入
export const productionInforEquipmentOutputByDayDelete = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentOutputByDayDelete',param) };//删除产量
export const productionInforEquipmentProductionTimeQuery = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentProductionTimeQuery',param) };//生产时间查询
// 设备级联下拉选
export const productionInforEquipmentSelect = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentSelect',param) };
export const productionInforEquipmentProductionTimeModification = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentProductionTimeModification',param) };//生产时间录入
export const productionInforEquipmentProductionTimeDelete = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentProductionTimeDelete',param) };//生产时间删除
// 编辑界面中设备操作员选择
export const productionInforOperatorSelect = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforOperatorSelect',param) };
// 编辑界面中物料型号选择
export const productionInforWorkpieceTypeSelect = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforWorkpieceTypeSelect',param) };
// 产量新增
export const productionInforEquipmentOutputByDayAdd = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentOutputByDayAdd',param) };
// 停机时间新增
export const productionInforEquipmentProductionTimeAdd = param => { return req.post('EMS.Thing.Api.ProductionInfor/Services/ProductionInforEquipmentProductionTimeAdd',param) };
// 设备级联下拉选
export const manufactureAnalysisOrganizationSelect= param => { return req.post('EMS.Thing.Api.ManufactureAnalysis/Services/ManufactureAnalysisOrganizationSelect',param) }; // 选择设备



